/*
Services. Check https://github.com/AmauriC/tarteaucitron.js/blob/master/tarteaucitron.services.js if you need to add more services.
Then adapt the service taking https://github.com/zemax/tartemeringuee/tree/master/services as an example.
*/

import consentUI from '../../lib/cookies/consent-ui'
import consentManager from '../../lib/cookies/consent-manager'
import gtm from 'tartemeringuee/services/gtm'

class Cookies {
  constructor () {
    if (typeof cookiesPolicyLink !== 'undefined') { /* eslint-disable */
      const consent = consentManager()
      if (typeof googleAnalyticsId !== 'undefined' && googleAnalyticsId !== '') {
        consent
          .register( gtm( googleAnalyticsId ) )
          .setUI( consentUI( {
                    'getText': getText( { 'privacyURL': cookiesPolicyLink } )
                  } ) )
          .launch()
          
          /* Event snippet for REG_ContactoInicioForm_Step1_2021-06-09 on https://payretailers.com/es/contacto/: Please do not remove.
          Place this snippet on pages with events you’re tracking. 
          Creation date: 06/11/2021 */
          gtm('event', 'conversion', {
            'allow_custom_scripts': true,
            'send_to': 'DC-11016160/reg_p0/reg_c0+standard'
          })

          gtm('event', 'conversion', {
            'allow_custom_scripts': true,
            'send_to': 'DC-11016160/reg_p0/hpg_h0+standard'
          })

          gtm('event', 'conversion', {
            'allow_custom_scripts': true,
            'send_to': 'DC-11016160/reg_p0/lpg_l0+standard'
          })

          gtm('event', 'conversion', {
            'allow_custom_scripts': true,
            'send_to': 'DC-11016160/reg_p0/reg_c00+standard'
          })
          
          gtm('event', 'conversion', {
            'allow_custom_scripts': true,
            'send_to': 'DC-11016160/reg_p0/reg_c000+standard'
          })

          gtm('event', 'conversion', {
            'allow_custom_scripts': true,
            'send_to': 'DC-11016160/reg_p0/reg_a0+standard'
          })
      }
    }
  }
}

export default Cookies
