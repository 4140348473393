import './scss/style.scss'
import Header from './js/template-parts/common/header.js'
import HeaderAnimation from './js/template-parts/common/header-animation.js'
import ModulesLoader from './js/modules-loader.js'
import Barba from './js/lib/barba.js'
import Cookies from './js/template-parts/popups/cookies.js'
import DisableScroll from './js/lib/disable-scroll'

// Load objects
document.addEventListener('DOMContentLoaded', function (event) {
  // Header
  window.header = new Header()
  window.headerAnimation = new HeaderAnimation()

  // BarbaJS
  window.barba = new Barba()
})

window.addEventListener('load', function (event) {
  // Ajax requests
  window.ajaxReq = function ajaxReq () {
    if (window.XMLHttpRequest) {
      return new XMLHttpRequest() // eslint-disable-line
    } else if (window.ActiveXObject) {
      return new ActiveXObject('Microsoft.XMLHTTP') // eslint-disable-line
    } else {
      console.log('Browser does not support XMLHTTP.')
      return false
    }
  }

  // Load JS scripts
  window.loadJS = function loadJS (file, callback) {
    // DOM: Create the script element
    var jsElm = document.createElement('script')
    // set the type attribute
    jsElm.type = 'application/javascript'
    // make the script element load file
    jsElm.src = file
    // add a callback
    jsElm.addEventListener('load', callback)
    // finally insert the element to the body element in order to load the script
    document.body.appendChild(jsElm)
  }

  // Modules loader
  window.modulesLoader = new ModulesLoader()
  window.modulesLoader.loadModules()

  // Disable scroll
  window.disableScroll = new DisableScroll()

  // Cookies banner
  window.cookies = new Cookies()
})
