import Swiper, { Navigation, Pagination } from 'swiper/core'

Swiper.use([Navigation, Pagination])

class SwiperLoader {
  constructor () {
    this.init()
  }

  init () {
    /****************
     * LOGOS SLIDER *
     ****************/

    /* eslint-disable no-new */
    new Swiper('.logos__swiper-container', {
      slidesPerView: 'auto',

      // If we need pagination
      pagination: {
        el: '.swiper-pagination'
      },

      // Navigation arrows
      navigation: {
        nextEl: '.custom-swiper-button-next',
        prevEl: '.custom-swiper-button-prev'
      },

      // And if we need scrollbar
      scrollbar: {
        el: '.swiper-scrollbar'
      }
    })

    /****************
     * POSTS SLIDER *
     ****************/

    /* eslint-disable no-new */
    new Swiper('.posts__swiper-container', {
      slidesPerView: 1,
      spaceBetween: 30,
      centerInsufficientSlides: true,
      watchOverflow: true,
      loop: true,

      breakpoints: {
        // when window width is >= 577px
        577: {
          slidesPerView: 2
        },
        // when window width is >= 769px
        769: {
          slidesPerView: 3,
          allowTouchMove: false,
          simulateTouch: false,
          loop: true
        }
      },

      navigation: {
        nextEl: '.custom-swiper-button-next',
        prevEl: '.custom-swiper-button-prev'
      },

      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    })

    /***********************
     * TESTIMONIALS SLIDER *
     ***********************/

    /* eslint-disable no-new */
    new Swiper('.testimonials__swiper-container', {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,

      breakpoints: {
        769: {
          slidesPerView: 2
        }
      },

      // Navigation arrows
      navigation: {
        nextEl: '.ts-swiper-button-next',
        prevEl: '.ts-swiper-button-prev'
      }
    })

    /***********************
     * OTHER GUIDES SLIDER *
     ***********************/

    /* eslint-disable no-new */
    new Swiper('.other-guides__swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 25,
      centerInsufficientSlides: true,
      watchOverflow: true,
      loop: true,

      breakpoints: {
        // when window width is >= 577px
        577: {
          slidesPerView: 2,
          loop: false,
          watchOverflow: true,

          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        },
        // when window width is >= 769px
        769: {
          slidesPerView: 3,
          loop: false,
          watchOverflow: true,

          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        }
      }
    })

    /**************************
     * PAYMENT METHODS SLIDER *
     **************************/

    /* eslint-disable no-new */
    new Swiper('.payment-methods__swiper-container', {
      slidesPerView: 1,
      spaceBetween: 35,
      centerInsufficientSlides: true,
      watchOverflow: true,
      loop: true,

      breakpoints: {
        // when window width is >= 768px
        768: {
          slidesPerView: 1.4,

          navigation: {
            nextEl: '.payment-methods__swiper-button-next',
            prevEl: '.payment-methods__swiper-button-prev'
          }
        }
      }
    })

    /*******************************
  * UPCOMING EVENTS SUPER SLIDER *
  ******************************/

    new Swiper('.upcoming_events__swiper-container', {
      slidesPerView: 1,
      spaceBetween: 50,
      watchOverflow: false,
      loop: true,
      breakpoints: {
        577: {
          slidesPerView: 2,
          centerInsufficientSlides: true,
          spaceBetween: 20,
          breakpointsBase: 'container',
          loop: true
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 3,
          centerInsufficientSlides: true,
          spaceBetween: 20,
          breakpointsBase: 'container',

          navigation: {
            nextEl: '.upcoming_events__swiper-button-next',
            prevEl: '.upcoming_events__swiper-button-prev',
            clickable: true
          }
        }
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    })
  }
}

export default SwiperLoader
