import Filterfromselect from './Filterfromselect'

class Interceptor {
  // function get all de params like name=lolote&age=12&....
  initfilter (e) {
    // make a array split tring like [name=lolote, age=127];
    this.split = e.split('&')
    // pass all data to this vairable
    let formdata = this.split

    // return all this values for get only data for diferent form but this is no equal
    function filtherthis (j) {
      const regex = new RegExp(
        /^variant?|^name?|^email?|^company?|^reason?|^surname?|^country?|^conpany?|^industry?|^webSite?|^website?|^phone?|^transation?|^message?|^personalData?|^marketing?/i
      )
      if (regex.test(j)) {
        return formdata
      }
    }
    // now formdata only have this items diltred
    formdata = formdata.filter(filtherthis) // variant = contact-ticket

    this.preparedata(formdata) // now call function line 🚸118 whit params filtred only whit unique form datas
  }

  transformdata (dataf) {
    var extradata = []
    if (dataf[8] !== '') {
      extradata.push(`Mensaje = ${decodeURIComponent(dataf[8])}`)
    }
    if (dataf[7] !== '') {
      extradata.push(`Transación monto = ${dataf[7]}`).toString()
    }
    if (dataf[10] !== '') {
      extradata.push(`Recibir marketing = ${dataf[10]}`).toString()
    }
    var mi = extradata.toString() + '\r\n'
    var tx = mi.split(',').toString() + '\r\n'
    var espa = tx.replaceAll(',', '\r\n')
    return espa
  }

  sendInfotoCRM (params) {
    const dataf = params
    var datafixed
    switch (dataf[0]) {
      case 'campaing_form':
        datafixed = {
          sourceTypeCode: 'LANDING_ES',
          fullName: dataf[1],
          email: dataf[2],
          companyName: dataf[3],
          websiteUrl: decodeURIComponent(dataf[4]),
          // comments: `reason: ${dataf[2]} \nMessage: ${dataf[3]}`,
          campaignId: 'cf931088-642d-42fb-a48f-6146ab0b9e1f'
        }
        break
      case 'custom_landing':
        datafixed = {
          sourceTypeCode: 'LANDING_ES',
          fullName: `${dataf[1]} ${dataf[2]}`,
          companyName: dataf[4],
          email: dataf[3],
          websiteUrl: decodeURIComponent(dataf[5]),
          phone: dataf[6],
          comments: this.transformdata(dataf),
          campaignId: 'cf931088-642d-42fb-a48f-6146ab0b9e1f'
        }
        break
      case 'contact':
        const selec = new Filterfromselect(dataf[5])
        datafixed = {
          fullName: dataf[1],
          companyName: dataf[3],
          industryTypeId: selec.getdatafromSlectInput(),
          email: dataf[2],
          sourceTypeCode: 'LANDING_ES',
          websiteUrl: decodeURIComponent(dataf[4]),
          country: dataf[6],
          comments: `mensaje: ${decodeURIComponent(dataf[7])}`,
          campaignId: dataf[5] // gabri
        }
        break
    }
    // console.log("1")
    // console.log(dataf)
    // console.log("2")

    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json; charset=utf-8')
    myHeaders.append(
      'Authorization',
      'Basic ODMzYjI4NWUtZDhiZC00NzVhLTg2ZTUtZWY0YTBhNmJlMjZlOmhZeWRZRFY0b2J4aXRmcndYQWJpNXVkaGl2RlJkQXc4'
    )
    myHeaders.append(
      'Cookie',
      'ARRAffinity=7b4c5d61fcfa8f59ce8e622ea84e60a81c829e32f919c770a4c74a8fa4e8c8f8; ARRAffinitySameSite=7b4c5d61fcfa8f59ce8e622ea84e60a81c829e32f919c770a4c74a8fa4e8c8f8'
    )

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(datafixed),
      redirect: 'follow'
    }
    var fetch = ''
    fetch(
      'https://payretailers.azurewebsites.net/api/v1/leads/landing-page',
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.info('Sussesfull!')
      })
      .catch((error) => console.log('error', error))
  }
  // prepare data have object whit the data to send to crm

  preparedata (data) {
    const param = []
    data.forEach((elem, i) => {
      const separe = elem.split('=')
      switch (separe[0]) {
        case 'email':
          separe[1] = unescape(separe[1])
          break
        default:
          separe[1] = decodeURI(separe[1])
          break
      }
      param.push(`${separe[1]}`)
    })
    this.sendInfotoCRM(param)
  }
}

export default Interceptor
