class Filteremail {
  constructor () {
    this.filterMails ()
  }

  filterMails () {
    if (document.querySelector('.contact-form__content #email')) {
      const selectorInputMail = document.querySelector('.contact-form__content #email')
      const emailControlMessage = document.getElementById('email__control-message')
      selectorInputMail.addEventListener('change', () => {
        const emailValue = selectorInputMail.value
        const RegExp = '@+(gmail|icloud|hotmail|facebook|yahoo|outlook|gmx|zoho|proton|tutanota)+(.)'
        const Results = emailValue.search(RegExp)
        Results !== -1 ? sendMessageError(emailControlMessage, selectorInputMail) : null
      })
    }

    function sendMessageError (emailControlMessage, input) {
      emailControlMessage.style.height = 'initial'

      setTimeout (() => {
        emailControlMessage.style.height = 0
        input.value = ''
      }, 3000)
    }
  }
}

export default Filteremail
