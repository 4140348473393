class HeaderAnimation {
  constructor () {
    this.initHeader()
  }

  initHeader () {
    const header = document.getElementById('header')
    let headerHeight = header.offsetHeight
    let distanceToTop = document.documentElement.scrollTop
    // Scroll event listener
    window.addEventListener('scroll', throttle(callback, 200))

    function throttle (callback, wait = 100) {
      let timer = null

      return function (args) {
        if (timer === null) {
          timer = setTimeout(() => {
            callback.apply(this, args)
            timer = null
          }, wait)
        }
      }
    }

    function callback () {
      const newDistanceToTop = document.documentElement.scrollTop
      headerHeight = header.offsetHeight

      if (newDistanceToTop <= 0) {
        header.classList.remove('scrolled', 'scrolled--show', 'scrolled--past')
        document.body.classList.remove('header-scrolled--show', 'header-scrolled--past')
      } else if (newDistanceToTop < distanceToTop) {
        if (newDistanceToTop > headerHeight) {
          header.classList.add('scrolled--show', 'scrolled--past') // The last one enables the css transitions.
          document.body.classList.add('header-scrolled--show', 'header-scrolled--past')
        } else {
          header.classList.add('scrolled--show')
          document.body.classList.add('header-scrolled--show')
        }
      } else if (newDistanceToTop > headerHeight) {
        header.classList.remove('scrolled--show')
        header.classList.add('scrolled')
        document.body.classList.remove('header-scrolled--show')
      }
      distanceToTop = newDistanceToTop
    }
  }
}

export default HeaderAnimation
