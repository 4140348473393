import { setNexStep } from './eventhelpper'
export const savedataOnCRM = (submit) => {
  setNexStep().loaderSpinnerPannel.style.display = 'flex'
  setNexStep().loaderSpinnerPannel.style.opacity = 1
  const blocksubmit = document.getElementById('popUp_Events-submit')
  const hiddeButtons = document.getElementsByClassName('popUp_Events-wrapper-form-wrapper-slider-buttons-submitReset')[0]
  blocksubmit.disabled = true
  hiddeButtons.style.display = 'none'
  const boxsecondPannel = setNexStep().secondPannel.children[0]
  boxsecondPannel.innerHTML = ' '
  const formData = new FormData(submit.form)
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json; charset=utf-8')
  myHeaders.append('Authorization', 'Basic ODMzYjI4NWUtZDhiZC00NzVhLTg2ZTUtZWY0YTBhNmJlMjZlOmhZeWRZRFY0b2J4aXRmcndYQWJpNXVkaGl2RlJkQXc4')
  myHeaders.append('Cookie', 'ARRAffinity=7b4c5d61fcfa8f59ce8e622ea84e60a81c829e32f919c770a4c74a8fa4e8c8f8; ARRAffinitySameSite=7b4c5d61fcfa8f59ce8e622ea84e60a81c829e32f919c770a4c74a8fa4e8c8f8')

  const body = {
    fullName: formData.get('popupName'),
    companyName: formData.get('popupCompany'),
    email: formData.get('popupMail'),
    sourceTypeCode: 'LANDING_ES',
    phone: formData.get('popupTel'),
    websiteUrl: formData.get('popupWeb'),
    country: 'US',
    comments: `Fecha: ${formData.get('popUp_Events-date')} \nTipo: ${formData.get('popUp_Events-Type')}`,
    industryTypeId: 'e-Commerce',
    campaignId: '9c5d99b7-db27-418e-9af5-197efe65d6fa'
  }

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect: 'follow'
  }

  fetch('https://payretailers.azurewebsites.net/api/v1/leads/landing-page', requestOptions)
    .then(response => response.json())
    .then(result => {
      if (typeof result.leadID === 'number') {
        boxsecondPannel.innerHTML += '<span> Thanks, We kep in touch soon.</span>'
        setTimeout(() => {
          setNexStep().loaderSpinnerPannel.style.display = 'none'
          setNexStep().loaderSpinnerPannel.style.opacity = 0
        }, 1000)
        setTimeout(() => {
          submit.form.reset()
          location.reload()
        }, 2500)
      }
    }).catch(error => {
      boxsecondPannel.innerHTML += `<span style="color:red;">${error}</span>`
      setTimeout(() => {
        setNexStep().loaderSpinnerPannel.style.display = 'none'
        setNexStep().loaderSpinnerPannel.style.opacity = 0
      }, 1000)
      setTimeout(() => {
        submit.form.reset()
        location.reload()
      }, 2000)
      console.error('error', error)
    })
}
