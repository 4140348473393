class PaymentMethods {
  constructor () {
    this.init()
  }

  init () {
    /*************
     * DOM NODES *
     *************/

    // Get payment method swiper container.
    const swiperContainer = document.querySelector(
      '.payment-methods__swiper-container'
    )

    // Get payment methods module dropdown menu.
    const dropdown = document.querySelector('.payment-methods__dropdown')

    // Get all payment methods module list items.
    const listItems = document.querySelectorAll('.payment-methods__list-item')

    /**********
     * EVENTS *
     **********/

    // Check for selection changes on dropdown menu.
    if (dropdown) {
      dropdown.addEventListener('change', moveToSlide)
    }

    // Check for clicks on each list item of the sidebar.
    listItems.forEach((item) => {
      item.addEventListener('click', moveToSlide)
    })

    // Check for changes on slide and update sidebar if visible.
    if (swiperContainer) {
      swiperContainer.swiper.on('slideChangeTransitionEnd', () => {
        if (window.innerWidth >= 992) {
          updateActiveSlideOnSidebar()
        }
      })
    }

    /*************
     * FUNCTIONS *
     *************/

    function moveToSlide (event) {
      if (event.type === 'click') {
        // Get current selected item (market)
        const selectedItem = document.querySelector(
          '.payment-methods__list-item--selected'
        )

        // Remove "selected" class from current selected element.
        selectedItem.classList.remove('payment-methods__list-item--selected')

        // Add "selected" class to clicked item.
        this.classList.add('payment-methods__list-item--selected')

        // Get data-slide attribute of the clicked item.
        const slide = parseInt(this.dataset.slide)

        // Move to selected slide (market)
        swiperContainer.swiper.slideToLoop(slide, 1000, false)
      } else if (event.type === 'change') {
        // Get value of selected option.
        const slide = parseInt(event.target.value)

        // Move to selected slide (market)
        swiperContainer.swiper.slideToLoop(slide, 1000, false)

        updateActiveSlideOnSidebar()
      }
    }

    function updateActiveSlideOnSidebar () {
      // Get active slide.
      const activeSlide = document.querySelector('.swiper-slide-active')

      // Convert list items NodeList to an array.
      const listItemsArr = Array.prototype.slice.call(listItems)

      // Get list item with data-slide attribute equal to current active slide.
      const activeListItem = listItemsArr.find(
        (item) => item.dataset.slide === activeSlide.dataset.swiperSlideIndex
      )

      // Get current selected item (market)
      const selectedItem = document.querySelector(
        '.payment-methods__list-item--selected'
      )

      // Remove "selected" class from current selected element.
      selectedItem.classList.remove('payment-methods__list-item--selected')

      // Highligh that list item.
      activeListItem.classList.add('payment-methods__list-item--selected')
    }
  }
}

export default PaymentMethods
