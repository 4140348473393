class CTA {
  constructor () {
    this.init()
  }

  init () {
    // Get all column headers on the page.
    const infoHeaders = document.querySelectorAll('.cta__info-header')

    // If some header with class .cta__info-header found on the page...
    if (infoHeaders.length > 0) {
      // Set height on page load
      setHeadersHeight()

      // Set height on window resize (also for device rotation).
      window.addEventListener('resize', setHeadersHeight, false)
    }

    /**
     * Finds out the tallest header and give the others its height so that
     * all columns have the same height, no matter how many lines the headers
     * have.
     */
    function setHeadersHeight () {
      // If viewport width >= 992px.
      if (window.innerWidth >= 992) {
        let maxHeight = 0

        // Check for the tallest header.
        infoHeaders.forEach((e) => {
          if (e.offsetHeight > maxHeight) {
            maxHeight = e.offsetHeight
          }
        })

        // Assign all headers the tallest height found.
        infoHeaders.forEach((e) => {
          e.style.height = `${maxHeight}px`
        })
      } else {
        // Reset headers height to its initial state.
        infoHeaders.forEach((e) => {
          e.style.height = 'initial'
        })
      }
    }
  }
}

export default CTA
