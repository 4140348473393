import LazyLoad from 'vanilla-lazyload'

class LazyLoading {
  constructor () {
    this.replaceNoscript()
    this.loadImages()
  }

  replaceNoscript () {
    const noscript = document.body.querySelectorAll('.image-wrapper noscript')
    let img
    let parent

    for (let i = 0; i < noscript.length; i++) {
      img = document.importNode((new DOMParser()).parseFromString(noscript[i].textContent, 'text/html').body.firstChild, true) /* eslint-disable-line */
      parent = noscript[i].parentElement
      parent.replaceChild(img, noscript[i])

      const imgs = parent.getElementsByTagName('img')

      for (let k = 0; k < imgs.length; k++) {
        // Replace original src attribute with data-src.
        const src = imgs[k].getAttribute('src')
        if (src !== null) {
          imgs[k].setAttribute('data-src', src)
          imgs[k].removeAttribute('src')
        }
        // Replace original srcset attribute with data-srcset.
        const srcset = imgs[k].getAttribute('srcset')
        if (srcset !== null) {
          imgs[k].setAttribute('data-srcset', srcset)
          imgs[k].removeAttribute('srcset')
        }
      }
    }
  }

  loadImages () {
    if ('lazyLoad' in window) {
      window.lazyLoad.update()
    } else {
      window.lazyLoad = new LazyLoad({ elements_selector: '.lazy' })
    }
  }
}

export default LazyLoading
