import { savedataOnCRM } from './savedataToCrm'
export const formItems = () => {
  const inputs = ['popupName', 'popupCompany', 'popupMail', 'popupTel', 'popupWeb', 'popupPosition']
  return inputs
}

// this return 0 if all first form is fullfiled
export const confirmNotEmptyFirstForm = () => {
  let count = formItems().length
  for (let inputs = 0; inputs < formItems().length; inputs++) {
    if (document.getElementsByName(formItems()[inputs])[0].value) {
      count--
    }
  }
  return count
}

export const showHiddeButonNext = () => {
  confirmNotEmptyFirstForm() === 0 ? showSubmitAndNextButton(true) : showSubmitAndNextButton(false)
}

export const setNexStep = () => {
  const dataEvent = {
    imageBig: document.getElementsByClassName('event_hero-wrapper-box-left-image')[0],
    dateEvent: document.getElementsByClassName('event_hero-wrapper-box-right-wrapper-spanFirst')[0],
    nameEvent: document.getElementsByClassName('event_hero-wrapper-box-right-wrapper-second-bloq')[0],
    dataEvemt: document.getElementsByClassName('popUp_Events-wrapper-header-right-name')[0],
    openPannel: document.getElementsByClassName('popUp_Events')[0],
    loaderSpinnerPannel: document.getElementsByClassName('popUp_Events-wrapper-loader')[0],
    firstPannel: document.getElementsByClassName('popUp_Events-wrapper-form-wrapper-slider-ul-infoData')[0],
    secondPannel: document.getElementsByClassName('popUp_Events-wrapper-form-wrapper-slider-ul-calendar')[0],
    inputEventDate: document.getElementsByName('popUp_Events-date')[0],
    inputEventType: document.getElementsByName('popUp_Events-Type')[0],
    bodyStyle: document.body.style
  }
  return dataEvent
}

export const openPanelSelectDate = (ContinueButton) => {
  setNexStep().loaderSpinnerPannel.style.display = 'flex'
  setTimeout(() => { setNexStep().loaderSpinnerPannel.style.opacity = 1 }, 500)
  setTimeout(() => {
    setNexStep().firstPannel.style.opacity = 0
    const eventDataDays = setNexStep().dateEvent.innerText
    const eventDataOnlyDays = eventDataDays.split(' ')[0].split('-')
    const eventDataMont = eventDataDays.split(' ')[1]
    const eventDataYears = eventDataDays.split(' ')[2]
    appearSecondPannel(eventDataOnlyDays, eventDataMont, eventDataYears)
  }, 1000)
  setTimeout(() => {
    setNexStep().firstPannel.style.height = 0
    setNexStep().secondPannel.style.height = 'auto'
  }, 1000)
  setTimeout(() => {
    setNexStep().loaderSpinnerPannel.style.opacity = 0
    setNexStep().loaderSpinnerPannel.style.display = 'none'
  }, 1000)
  setTimeout(() => {
    // hide button continue. not more neccesary more
    ContinueButton.display = 'none'
  }, 1000)
}

const appearSecondPannel = (getdays, mont, year) => {
  const init = getdays[0]
  const end = getdays[1]
  const days = []
  if ('es' === scripts_ajax_variables.lang) {
    days.push('Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado')
  } else {
    days.push('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday')
  }

  const boxsecondPannel = setNexStep().secondPannel.children[0]

  for (var y = init; y <= end; y++) {
    // mont need be in english
    const birthday = new Date(`${getMontInNumber(mont)} ${y}, ${year}`)
    const day1 = birthday.getDay()
    boxsecondPannel.innerHTML += `<li><p id='setDateInPopUp_${y}_${days[day1] + '-' + y}'>${days[day1] + '-' + y}</p></li>`
  }
}

export const openPanelSelectHour = (dateToSaver) => {
  const boxsecondPannel = setNexStep().secondPannel.children[0]
  setNexStep().loaderSpinnerPannel.style.display = 'flex'
  setTimeout(() => {
    setNexStep().loaderSpinnerPannel.style.opacity = 1
    boxsecondPannel.innerHTML = ''
    let kindOfCall = []
    if ('es' === scripts_ajax_variables.lang) {
      kindOfCall.push('Llamada', 'Video Llamada')
    } else {
      kindOfCall.push('Call', 'VideoCall')
    }
    for (let Hour = 0; Hour < kindOfCall.length; Hour++) {
      boxsecondPannel.innerHTML += `<li><p id='setKindCall_${kindOfCall[Hour]}'>${kindOfCall[Hour]}</p></li>`
    }
  }
  , 500)
  setNexStep().inputEventDate.value = dateToSaver
  setTimeout(() => { setNexStep().loaderSpinnerPannel.style.display = 'none'
    setNexStep().loaderSpinnerPannel.style.opacity = 0 }, 1000)
  // console.log(listOfHour)
  // let eurSelector =  `<select>
  // </select>`
}

export const setCallType = (callType) => {
  callType ? setNexStep().inputEventType.value = callType : null

  const showResumeOfData = ['Name:', 'Business:', 'Email:', 'Telephone:', 'Website:', 'Positiom:', 'Date:', 'Type:']
  const itemsForm = [formItems()]
  setNexStep().loaderSpinnerPannel.style.display = 'flex'
  setNexStep().loaderSpinnerPannel.style.opacity = 1

  const boxsecondPannel = setNexStep().secondPannel.children[0]
  boxsecondPannel.innerHTML = ''
  const completAllItemsOfForm = itemsForm.push('popUp_Events-date', 'popUp_Events-Type')
  const allItemsOfForm = itemsForm.flat()
  boxsecondPannel.innerHTML += '<section class="listOfSelectedDate">'
  for (let i = 0; i < showResumeOfData.length; i++) {
    boxsecondPannel.children[0].innerHTML += `<div><b>${showResumeOfData[i]}</b>
        <span>${document.getElementsByName(allItemsOfForm[i])[0].value}</span></div>`
  }
  boxsecondPannel.innerHTML += '</section>'
  setTimeout(() => { setNexStep().loaderSpinnerPannel.style.display = 'none'; setNexStep().loaderSpinnerPannel.style.opacity = 0 }, 1000) }

 let showSubmitAndNextButton = (active) => {
  const btnCont = document.getElementById('popUp_Events-continue')
  const submt = document.getElementById('popUp_Events-submit')
  if (active) {
    btnCont.style.display = 'block'
    submt.classList.remove('popUp_Events_submitInactive')
    submt.disabled = false
    CheckAndSavedataToCRM(submt)
  } else {
    btnCont.style.display = 'none'
    submt.classList.add('popUp_Events_submitInactive')
    submt.disabled = true
  }
}

const getMontInNumber = (mont) => {
  const montsES = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
  const montsEN = ['JAN', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
  let selectedMont
  const searcher = (mont) => {
    for (let g = 0; g < 12; g++) {
      if (montsES[g] === mont) {
        selectedMont = g
        return
      }
      if (montsEN[g] === mont) {
        selectedMont = g
        return
      }
    }
  }
  searcher(mont)
  return montsEN[selectedMont]
}

const CheckAndSavedataToCRM = (submit) => {
  // submit.preventDefault()
  const messagePrompt = 'es' !== scripts_ajax_variables.lang ? 'Acept to continue set data and type metting or cancel to send only this data...' : 'Acepte para continuar sin colocar la fecha y el tipo de llamada que desea recibir...'
  submit.addEventListener('click', (e) => {
    e.preventDefault()
    if (!setNexStep().inputEventDate.value || !setNexStep().inputEventType.value) {
      if (!confirm(messagePrompt)) { savedataOnCRM(submit) }
    } else { savedataOnCRM(submit) }
  })
}
