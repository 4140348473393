class CustomSelects {
  constructor () {
    this.init()
  }

  init () {
    const selects = document.querySelectorAll('.custom-select > select');
    [...selects].forEach(select => {
      select.addEventListener('change', () => {
        select.parentNode.classList.add('is-selected')
      })
    })
  }
}

export default CustomSelects
