import LazyLoading from './lib/lazy-loading.js'
import SwiperLoader from './swiper.js'
import CustomSelects from './template-parts/common/custom-selects.js'
import Accordion from './template-parts/components/accordion.js'
import ContactForm from './template-parts/sections/contact-form.js'
import CTA from './template-parts/sections/cta-big.js'
import PaymentMethods from './template-parts/sections/payment-methods.js'
import BlogPostHeader from './template-parts/sections/blog-post-header.js'
import Hero from './template-parts/sections/hero.js'
import Animations from './lib/animations.js'
import Filteremail from './template-parts/sections/Filteremail.js'
import Uploader from './template-parts/sections/fileUploader'
import Submitevents from './template-parts/events/Submiteventstocrm.js'
import TrigguerEvents from './template-parts/events/TrigguerEvents.js'

export default class ModulesLoader {
  constructor (el = document) {
    this.el = el
  }

  loadModules () {
    window.lazyLoading = new LazyLoading()
    window.contactForm = new ContactForm()
    window.SwiperLoader = new SwiperLoader()
    window.CTA = new CTA()
    window.Accordion = new Accordion()
    window.CustomSelects = new CustomSelects()
    window.PaymentMethods = new PaymentMethods()
    window.BlogPostHeader = new BlogPostHeader()
    window.Hero = new Hero()
    window.animation = new Animations()
    window.Filteremail = new Filteremail()
    window.Uploader = new Uploader()
    window.Submitevents = new Submitevents()
    window.TrigguerEvents = new TrigguerEvents()
  }
}
