import * as helper from './helpper/eventhelpper'
class Submitevents {

  constructor () {
    if (document.getElementsByClassName('popUp_Events-closeButton')[0]) {
      this.closeBtn = document.getElementsByClassName('popUp_Events-closeButton')[0]
    }
    const close = this.closeBtn
    if (document.getElementById('popUp_Events-continue')) {
      this.continue = document.getElementById('popUp_Events-continue')
    }
    const btnCont = this.continue
    if (document.getElementsByClassName('popUp_Events-wrapper-form-wrapper-slider-ul-calendar-wrapper')[0]) {
      this.selectDate = document.getElementsByClassName('popUp_Events-wrapper-form-wrapper-slider-ul-calendar-wrapper')[0]
    }
    const selectdate = this.selectDate
    if (document.getElementById('popUp_step_03')) {
      this.AtTheEndThanks = document.getElementById('popUp_step_03')
    }
    const thanks = this.AtTheEndThanks

    if (document.getElementsByClassName('popUp_Events-wrapper-form-wrapper-slider-buttons-Reset-triguer')[0]) {
      this.resetBtn = document.getElementsByClassName('popUp_Events-wrapper-form-wrapper-slider-buttons-Reset-triguer')[0]
    }
    const resetBtnTrigger = this.resetBtn

    //   if( document.getElementById('popUp_Events-submit') ){
    //      this.submit = document.getElementById('popUp_Events-submit')
    //   }
    //   const submitBTN = this.submit

    this.inputs = ['popupName', 'popupCompany', 'popupMail', 'popupTel', 'popupWeb', 'popupPosition']
    const ListInputs = this.inputs

    this.init(btnCont, selectdate, thanks, ListInputs, close, resetBtnTrigger)

    this.continueNextStep = this.continueNextStep.bind(this)
  }

  init (btnCont, selectdate, thanks, ListInputs, close, resetBtnTrigger) {
    // const allImputs = []

    for (let inputs = 0; inputs < ListInputs.length; inputs++) {
      if (document.getElementsByName(ListInputs[inputs])[0]) {
      // allImputs.push(document.getElementsByName(ListInputs[inputs])[0])
        document.getElementsByName(ListInputs[inputs])[0].addEventListener('change', this.filterImputInfo)
      }
    }

    resetBtnTrigger.addEventListener('click', () => {
      const resetBtnForm = document.getElementsByClassName('popUp_Events-wrapper-form-wrapper-slider-buttons-Reset')[0]
      resetBtnForm.click()
    })

    btnCont.addEventListener('click', this.continueNextStep)
    close.addEventListener('click', this.closePannel)
    selectdate.addEventListener('click', this.setDateInHiddenForm)
  }

  filterImputInfo (e) {
  /* FILTRO PARA ANTI JACK EN EL INPUT NOMBRE */

    helper.showHiddeButonNext() // appear o disappear button next if is full the form
    const validityBadCharter = /[<>[\]=+/*%!?@#$`|\\^;:(")'}{]/g
    const validationEmail = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]/g
    const validationTel = /[a-zA-Z<>[\]=/*%!?@#$`|\\^;:(")'}{]/g
    const validityWeb = /[<>[\]=+*%!?@#$`|\\^;(")'}{]/g
    const badEmailFilter = {
      gmail: /[a-zA-Z0-9_.+-]+@[gmail]+\.[a-zA-Z0-9-.]/g,
      outlook: /[a-zA-Z0-9_.+-]+@[outlook]+\.[a-zA-Z0-9-.]/g,
      hotmail: /[a-zA-Z0-9_.+-]+@[hotmail]+\.[a-zA-Z0-9-.]/g,
      yahoo: /[a-zA-Z0-9_.+-]+@[yahoo]+\.[a-zA-Z0-9-.]/g,
      icloud: /[a-zA-Z0-9_.+-]+@[icloud]+\.[a-zA-Z0-9-.]/g,
      aol: /[a-zA-Z0-9_.+-]+@[aol]+\.[a-zA-Z0-9-.]/g,
      zoho: /[a-zA-Z0-9_.+-]+@[zoho]+\.[a-zA-Z0-9-.]/g,
      gmx: /[a-zA-Z0-9_.+-]+@[gmx]+\.[a-zA-Z0-9-.]/g,
      yandex: /[a-zA-Z0-9_.+-]+@[yandex]+\.[a-zA-Z0-9-.]/g,
      mail: /[a-zA-Z0-9_.+-]+@[mail]+\.[a-zA-Z0-9-.]/g,
      lycos: /[a-zA-Z0-9_.+-]+@[lycos]+\.[a-zA-Z0-9-.]/g,
      facebook: /[a-zA-Z0-9_.+-]+@[facebook]+\.[a-zA-Z0-9-.]/g
    }

    switch (e.target.name) {
      case 'popupName':
        const checkName = document.getElementsByName('popupName')[0]
        const result = validityBadCharter.test(checkName.value)
        if (result === true) {
          const valueImput = checkName.value
          checkName.style.backgroundColor = '#f8d1d1'
          setTimeout(() => {
            checkName.style.backgroundColor = 'initial'
            checkName.value = valueImput.replace(validityBadCharter, '')
          }, 1000)
        }

        break
      case 'popupCompany':
        const checkCompany = document.getElementsByName('popupCompany')[0]
        const resultCompany = validityBadCharter.test(checkCompany.value)
        if (resultCompany === true) {
          const valueImputCompany = checkCompany.value
          checkCompany.style.backgroundColor = '#f8d1d1'
          setTimeout(() => {
            checkCompany.style.backgroundColor = 'initial'
            checkCompany.value = valueImputCompany.replace(validityBadCharter, '')
          }, 1000)
        }
        break
      case 'popupMail':
        const checkEmail = document.getElementsByName('popupMail')[0]
        const emailDisclamer = document.getElementById('mail_disclamer')
        const resultEmail = validationEmail.test(checkEmail.value)
        if (resultEmail === true) {
          emailDisclamer.style.display = 'none'
          const emailFilter = ['gmail', 'outlook', 'hotmail', 'yahoo', 'icloud', 'aol', 'zoho',
            'gmx', 'yandex', 'mail', 'lycos', 'facebook', 'icloud']
          emailFilter.map((mails) => {
            if (badEmailFilter[mails].test(checkEmail.value)) {
              checkEmail.value = ''
              emailDisclamer.style.display = 'inline'
              setTimeout(() => {
                emailDisclamer.style.display = 'none'
              }, 3000)
            }
          })
        } else {
          emailDisclamer.style.display = 'inline'
          checkEmail.value = ''
          setTimeout(() => {
            emailDisclamer.style.display = 'none'
          }, 3000)
        }
        break
      case 'popupTel':
        const checkTel = document.getElementsByName('popupTel')[0]
        const resultTel = validationTel.test(checkTel.value)
        if (resultTel === true) {
          const valueImputCompany = checkTel.value
          checkTel.style.backgroundColor = '#f8d1d1'
          setTimeout(() => {
            checkTel.style.backgroundColor = 'initial'
            checkTel.value = valueImputCompany.replace(validationTel, '')
          }, 1000)
        }
        break
      case 'popupWeb':
        const checkWeb = document.getElementsByName('popupWeb')[0]
        const resultWeb = validityWeb.test(checkWeb.value)
        if (resultWeb === true) {
          const valueImputWebsite = checkWeb.value
          checkWeb.style.backgroundColor = '#f8d1d1'
          setTimeout(() => {
            checkWeb.style.backgroundColor = 'initial'
            checkWeb.value = valueImputWebsite.replace(validityWeb, '')
          }, 1000)
        }
        break
      case 'popupPosition':
        const checkPosition = document.getElementsByName('popupPosition')[0]
        const resultPosition = validityBadCharter.test(checkPosition.value)
        if (resultPosition === true) {
          const valueImputWebsite = checkPosition.value
          checkPosition.style.backgroundColor = '#f8d1d1'
          setTimeout(() => {
            checkPosition.style.backgroundColor = 'initial'
            checkPosition.value = valueImputWebsite.replace(validityBadCharter, '')
          }, 1000)
        }
        break
      default:
        break
    }
  }

  continueNextStep (event) {
    event.preventDefault()
    const ContinueButton = event.target.style // block
    !helper.confirmNotEmptyFirstForm() ? helper.openPanelSelectDate(ContinueButton) : console.log('A element is empty...!')
  }

  setDateInHiddenForm (dateevent) {
    switch (dateevent.target.id.split('_')[0]) {
      case 'setDateInPopUp':
        helper.openPanelSelectHour(dateevent.target.id.split('_')[2])
        break
      case 'setKindCall':
        helper.setCallType(dateevent.target.id.split('_')[1])
        break
      default:
        console.error('Try to click exacly on the button')
        break
    }
  }

  closePannel (item) {
    const pannel = item.target.offsetParent.offsetParent
    pannel.style.display = 'none'
    // const thisForm =
    window.location.reload()
  }
}

export default Submitevents
